/*----- COLOR VARIABLES -----*/

:root {
    /*mint*/
    --primary-color: #84A59D;
    /*dark purple*/
    --dark-text: #351d77;
    /*light green*/
    --light-bg-main: #e8f0e4;
    /*dark blue*/
    --dark-bg: #002060;
    /*peach*/
    --accent-text: #F28482;
    /*beige*/
    --light-bg-accent: #f7ede2;
}



/*----- MOBILE -----*/
/*Basic Ish*/
html {
    font-size: 16px;
    scroll-behavior: smooth;
}

body {
    font-family: 'Nunito Sans', sans-serif;
    font-size: 1em;
    background: #e8f0e4;
    color: #351d77;
    width: 100%;
}

#root {
    width: 100%;
}

figure {
    margin: 0;
}

h1, h2, h3, h4, h5, h6 {
    font-family: 'Alata', sans-serif;
    margin: 0;
}

h2, h3, h4, h5, h6 {
    font-weight: normal;
    text-align: center;
}

h1 {
    font-size: 2em;
    line-height: 1.25em;
}

h2 {
    font-size: 2em;
}

h3 {
    font-size: 1.75em;
}

h4 {
    font-size: 1.5em;
}

ul,
ol {
    list-style: none;
    padding: 0;
    margin: 0;
}

img {
    max-width: 100%;
    max-height: 100%;
    width: auto;
    height: auto;
}

p {
    font-size: 1.25em;
    line-height: 1.5em;
    margin: 0;
}


address {
    font-style: normal;
}

.illuminati {
    display: none;
}


/*Set up flex containers*/
.container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 0;
    margin: auto;
    max-width: 600px;
    margin: 0 auto;
}

.flex-row {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
}

.flex-col {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

main {
    width: 100%;
}

header, #skills, footer {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.content-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
}

/* mobile header */
header {
    background: #e8f0e4;
    width: 100%;
}

    header .content-wrapper {
        padding: 0 20px 80px 20px;
    }

    header nav {
        display: flex;
        justify-content: space-around;
        align-items: center;
        flex-flow: row wrap;
        width: 100%;
        margin: 30px 0 80px 0;
    }

        header nav ul {
            display: flex;
            justify-content: space-around;
            width: 100%;
        }

        header nav li {
            border-radius: 15px;
            padding: 5px;
            max-width: 100px;
            min-width: 50px;
            text-align: center;
        }

        header nav a {
            text-decoration: none;
            color: #351d77;
        }

nav li a:active {
    background: #EEE4F0;
}

header nav li:hover {
    background: #EEE4F0;
}


header p {
    margin: 15px 0;
    text-align: center;
}

    header p:last-child {
        margin-bottom: 30px;
    }


h1 {
    margin-bottom: 15px;
    text-align: center;
}

    h1 span {
        color: #351d77;
        text-shadow: -2px 2px #f4bedc;
        font-size: 1.25em;
    }

#char-sprite {
    position: absolute;
    top: 150px;
    left: -100px;
    width: 100px;
    height: 100px;
    background-image: url("http://localhost:3000/img/char-blue-haired-avatar-staff.png");
    background-size: 1300%;
    background-position-x: 0;
    background-position-y: -1101px;
    animation: walk 2 1s steps(8),enter-sprite 2s linear forwards;
    animation-delay: 1s;
}

@keyframes enter-sprite {
    from {
        left: -100px;
    }

    to {
        left: 200px;
    }
}


@keyframes walk {
    from {
        background-position-x: 0;
    }

    to {
        background-position-x: -800px;
    }
}

@keyframes spellcast {
    from {
        background-position-x: 0;
        background-position-y: -200px;
    }

    to {
        background-position-x: -600px;
        background-position-y: -200px;
    }
}




/* mobile projects */
#projects {
    box-sizing: border-box;
    border-radius: 15px;
    background: white;
    padding: 20px;
    margin: auto;
    width: 90%;
}

    #projects .content-wrapper {
        margin: auto;
    }

.project-items {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 80%;
    margin: auto;
}

.project {
    background: #EEE4F0;
    border-radius: 15px;
    margin: 20px 0;
    padding: 25px 10px;
}

    .project figure {
        max-width: 90%;
        height: auto;
        margin: 0 auto;
        display: flex;
        justify-content: center;
        align-items: baseline;
    }

    .project img {
        box-shadow: 5px 10px 10px #afafaf;
        border-radius: 15px;
    }

    .project h4 {
        margin: 20px 0 30px 0;
    }

    .project p {
        margin: 10px 0;
    }

    .project .description {
        margin-bottom: 30px;
    }

    .project a {
        background: var(--dark-text);
        border-radius: 50px;
        color: white;
        display: block;
        font-weight: bold;
        margin: 30px auto 0 auto;
        padding: 15px 0;
        text-align: center;
        text-decoration: none;
        text-transform: uppercase;
        width: 200px;
    }

/* mobile skills */
#skills {
    background: #e8f0e4;
}

    #skills .content-wrapper {
        padding: 60px 0 60px 0;
    }


.skills-content article {
    margin: 40px 0 0 0;
}


.skills-content li {
    background: #EEE4F0;
    margin-top: 20px;
    padding: 15px 0;
    text-align: center;
}

/* mobile about */
#about {
    box-sizing: border-box;
    border-radius: 15px;
    background: white;
    padding: 40px 20px;
    margin: auto;
    width: 90%;
}

    #about figure {
        max-width: 90%;
        max-height: 300px;
        margin: 0 auto 40px auto;
        display: flex;
        justify-content: center;
        align-items: baseline;
    }

    #about img {
        border-radius: 15px;
    }

.about-me-blurb {
    width: 80%;
}

#about h2 {
    text-align: left;
    line-height: 1em;
}

    #about h2 span {
        font-size: .75em;
        /*color: #ec91c4;
        text-shadow: -1px 1px 0 #351d77;*/
        color: #351d77;
        text-shadow: -1px 1px #f4bedc;
    }

#about h4 {
    margin-top: 20px;
    text-align: left;
}

    #about h4 i {
        font-size: .9em;
    }

#about p {
    margin: 20px 0;
}

.resume-buttons {
    text-align: center;
    margin: 15px auto;
}

    .resume-buttons a {
        background: #f4bedc;
        border-radius: 15px;
        padding: 5px 10px;
        text-decoration: none;
        color: #351d77;
        font-weight: bold;
    }



#about article {
    background: #351d77;
    border-radius: 15px;
    color: #EEE4F0;
    display: flex;
    font-size: .9em;
    justify-content: center;
    align-items: center;
    flex-flow: row wrap;
    width: 80%;
    padding: 20px 0;
}

    #about article p {
        font-size: 1.25em;
        margin: 0;
        text-align: center;
        width: 100%;
    }

        #about article p#topic {
            color: #f4bedc;
            cursor: pointer;
            font-weight: bold;
            height: 60px;
            user-select: none;
            display: flex;
            justify-content: center;
            align-items: center;
        }

/* mobile footer */
footer {
    background: #e8f0e4;
}

    footer .content-wrapper {
        padding: 80px 0 20px 0;
    }

    footer h4 {
        margin: 60px 0 30px 0;
    }

    footer .message-me {
        text-align: center;
    }

.message-me a {
    background: #351d77;
    border-radius: 15px;
    padding: 5px;
    text-decoration: none;
    color: #e8f0e4;
    font-weight: bold;
}

.message-me div {
    margin-top: 20px;
}

footer .social-icons {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}

    footer .social-icons p {
        display: flex;
        font-size: 1.75em;
        justify-content: center;
        line-height: 0;
        align-items: center;
        background-color: #351d77;
        width: 0;
        height: 0;
        padding: 20px;
        border-radius: 100%;
    }

    footer .social-icons a {
        color: #e8f0e4;
    }

.copyright {
    font-size: .875em;
    margin: 60px 0 0 0;
    text-align: center;
    width: 100%;
}

.heart {
    /*color: #ec91c4;
    text-shadow: -.5px .5px 0 #351d77;*/
    color: #351d77;
    text-shadow: -.75px .75px #f4bedc;
}

/*----- mobile resume -----*/
#resume {
    position: relative;
    top: 0;
    left: 0;
    box-sizing: border-box;
    width: 100%;
    background: var(--light-bg-main);
    color: var(--dark-text);
}

#resume h1, #resume h2, #resume h3, #resume h4 {
    font-weight: bold;
    margin: 0;
}

    #resume h2 {
        font-size: 1.25em;
    }

    #resume h3 {
        font-size: 1.5em;
        
    }

#resume h4 {
    font-size: 1.2em;
    text-align: left;
}

#resume h5 {
    font-size: 1em;
    font-style: italic;
    text-align: left;
    margin-top: 10px;
}

#resume .job-col h5 {
    margin: 0;
}

.mint {
    color: var(--primary-color);
    /*color: #84A59D;
    background-color: var(--primary-color);*/
}

.salmon {
    color: #F28482;
}

#resume .contact-block, #resume .bio-block, #resume .project-block, #resume .blue-block, #resume .exp-block {
    box-sizing: border-box;
    padding: 25px;
}


#resume .header-row, #resume .bio-row, #resume .skills-and-exp-row {
    width: 100%;
    box-sizing: border-box;
    flex-wrap: wrap;
}

#resume .name-block {
    position: relative;
    width: 100%;
    box-sizing: border-box;
    background-color: white;
    padding: 10px;

}

.name-block > * {
    z-index: 1;
}

    #resume .name-block:after {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        background-color: var(--accent-text);
        z-index: 0;
        opacity: .5;
    }

    #resume .name-block h1 {
        -webkit-text-stroke: 0.5px #002060;
    }

    #resume .icon-block {
        margin-right: 15px;
    }

#resume figure {
    box-sizing: border-box;
    width: 100px;
    height: 100px;
    background-color: var(--primary-color);
    border-radius: 100%;
    padding: 5px;

}

#resume figure img {
    max-width: 100%;
    max-height: 100%;
    width: auto;
    height: auto;
}

#resume figure img.fit-to-circ {
    max-width: 80%;
    max-height: 80%;
}

#resume .contact-block {
    background-color: var(--light-bg-accent);
    box-sizing: border-box;
    width: 100%;
}

#resume .contact-block .flex-row {
    width: 100%;
    justify-content: space-between;
    margin: 5px auto;
}

#resume .bio-block p {
    margin: 5px auto;
}

#resume .bio-block .flex-row a:hover {
    font-size: 1.25em;
    /*how bout a keyframe to make it change color?*/
}

#resume .featured-project {
    padding: 5px;
}

#resume .featured-project a {
    color: inherit;
    font-weight: bold;
}


#resume .blue-block {
    color: white;
    background-color: var(--dark-bg);
}

#resume #skill-block p, #resume .skill-block ul {
    margin-top: 15px;
}

#resume .edu-block {
    max-width: 350px;
    margin-top: 15px;
}

#resume .edu-block p {
    margin-top: 15px;
}

#resume .blue-block ul.flex-row, #resume .bio-block .flex-row {
    flex-wrap: wrap;
}

    #resume .blue-block ul.flex-row li, #resume .bio-block .flex-row a {
        min-width: 30%;
        padding: 5px;
        color: inherit;
        text-align: center;
    }

#resume .exp-block {
    background-color: var(--light-bg-accent);

}


    #resume .exp-block h4 {
        color: var(--accent-text);
    }

#resume .exp-block .flex-row {
    align-items: start;
}

#resume .exp-block .job-block {
    width: 100%;
}

#resume .exp-block .date-col {
    text-align: right;
    padding-top: 10px;
    position: relative;
    width: 20%;
}

    #resume .exp-block .job-col {
        padding: 10px 0 10px 50px;
        border-bottom: 1px solid var(--accent-text);
        position: relative;
        width: 80%;
    }

    #resume .exp-block .job-col:before {
        content: '';
        position: absolute;
        left: 15px;
        top: 20px;
        width: 6px;
        height: 6px;
        border: 2px solid;
        background-color: var(--accent-text);
        border-radius: 100%;
        z-index: 1;
    }

        #resume .exp-block .job-col:after {
            content: '';
            position: absolute;
            left: 19px;
            top: 20px;
            width: 0;
            height: 100%;
            border: 1px solid;
            background-color: var(--dark-text);
            transition: 1.5s all ease .75s;
            
        }

        #resume .exp-block .reveal .job-col:after {
            height: 0;
        }
        
        #resume .exp-block .reveal.active .job-col:after {
            height: 100%;
        }



        #resume .exp-block ul {
            list-style: disc;
            margin: 5px;
            padding: 5px;
        }

        #resume .exp-block ul ul {
            list-style: circle;
        }


.reveal {
    position: relative;
    transform: translateY(50px);
    opacity: 0;
    transition: 1s all ease;
}

#resume .reveal h3, #resume h3.reveal {
    transition: 1s all ease;
    text-shadow: 0px 3px var(--accent-text),0 5px var(--accent-text), 0 7px var(--accent-text);
}


#resume .active .mint, #resume .mint.active {
    animation: bounce-shadow forwards 2s;
}

@keyframes bounce-shadow {
    from {
        text-shadow: 0px 3px var(--accent-text),0 5px var(--accent-text), 0 7px var(--accent-text);
    }
    to {
        text-shadow: none;
    }
}


.reveal.active {
    transform: translateY(0);
    opacity: 1;
}

@keyframes bounce-up {
    from {
        transform: translateY(50px);
        opacity: 0;
    }

    to {
        transform: translateY(0);
        opacity: 1;
    }
}


/*----- RESUME TEMPLATE -----*/

#button-slider {
    position: relative;
    top: 0;
    left: 0;
    z-index: 1000;
    width: 100%;
    background: white;
    box-sizing: border-box;
    padding: 25px;
    transition: .5s ease;
}

    #button-slider.closed {
        height: 0;
        overflow: hidden;
        transition: .5s ease;
        padding: 0;
    }

    #button-slider p {
        margin: 10px 15px;
        padding: 5px;
        font-size: 1em;
        text-align: center;
    }

    #button-slider .flex-row.info-blurb {
        align-items: start;
    }

#button-slider.closed .color-list, #button-slider.closed .color-picker {
    display: none;
}

@keyframes bounce {
    0%, 20%, 50%, 80%, 100% {
        transform: translateY(0);
    }

    40% {
        transform: translateY(-30px);
    }

    60% {
        transform: translateY(-15px);
    }
}

.bounce {
    animation-name: bounce;
    animation: bounce 1s ease 1;
}


.buttons-button {
    z-index: 1000;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    top: -15px;
    width: 10vw;
    margin: 0 auto;
}

.palette-item {
    cursor: pointer;
    position: relative;
    width: 100px;
    height: 100px;
    border: 3px solid;
    border-radius: 25px;
    text-shadow: 1px 1px white;
    margin: 5px;
    transition: ease 1s;
}

.color-list.flex-row {
    flex-wrap: wrap;
}

.color-picker {
    padding: 25px;
    box-sizing: border-box;
    position: absolute;
    right: 0;
    box-shadow: 0 5px 5px rgba(0,0,0,.4);
    border: 3px solid #351d77;
    border-radius: 25px;
    overflow: hidden;
    background-color: white;
    color: black;
    z-index: 100;
}

    .color-picker ul {
        padding: 10px 0;
        margin-top: 15px;
        height: 300px;
        overflow-y: scroll;
        border-radius: 5px;
        box-shadow: 5px 5px 5px rgba(0,0,0,.4);
    }

    .color-picker li {
        padding: 5px;   
    }
    .color-picker li span {
        margin-right: 10px;
    }


.color-option-preview {
    display: inline-block;
    width: 15px;
    height: 15px;
    border-radius: 5px;
}

.color-option {
    padding: 5px;
    cursor: pointer;
    border-radius: 5px;
    display: flex;
    justify-content: space-between;
}

#color-preview {
    border: 3px solid #351d77;
    border-radius: 25px;
    width: 150px;
    height: 150px;
    box-sizing: border-box;
    margin: auto;
}

.heart {
    opacity: .5;
    transition: ease 1s;
    fill: white;
    filter: drop-shadow(3px 5px 2px rgb(0 0 0 / 0.4));
    cursor: pointer;
}

#color-preview:hover .heart {
    opacity: 1;
}

.edit-res-exp, .edit-res-proj, .edit-res-link, .edit-res-edu {
    padding: 10px;
    border-bottom: 2px dashed;
    align-items:start;
}

.edit-res-edu {
    align-items: flex-end;
}

.edit-res-edu .span-button {
    margin: 0;
}


.hunny-p {
    width: 100%;
}

.eighty-p {
    width: 80%;
}

.twenty-p {
    width: 20%;
}

.align-start {
    align-items: start;
}

.span-button {
    cursor: pointer;
    display: flex;
    background: #F5CAC3;
    color: #351d77;
    border: 2px solid;
    font-weight: bold;
    padding: 5px 10px;
    text-align: center;
    box-sizing: border-box;
    margin: 10px auto;
    border-radius: 15px;
    text-transform: lowercase;
    min-width: 30px;
    min-height: 30px;
    justify-content: center;
}

.span-button.xl {
   min-width: 150px;
   padding: 10px 15px;
   text-transform: initial;
}

.span-button.shade {
    opacity: .5;
}

.span-button.selected {
    color: #f5cac3;
    background: #351d77;
    opacity: 1;
}

#always-visible {
    position: fixed;
    z-index: 10000;
    left: 25px;
    top: 30vh;

}

#confirmation-box {
    position: fixed;
    z-index: 1000000;
    font-size: 1.5em;
    text-align: center;
    width: 100%;
    top: 0;
    left: 0;
    height: 100vh;
    background-color: rgba(0,0,0,.5);
    transition: .5s ease;
}

.inner-conf {
    background: white;
    padding: 25px;
    margin: 20vh ;
    border-radius: 25px;
}

input, textarea {
    border: none;
    border: 2px solid #84A59D;
    background: transparent;
    font-family: inherit;
    font-size: 16px;
    color: inherit;
    padding: 5px;
    max-width: 200px;
    border-radius: 5px;
    /*height: 1em;*/
    /* display: flex; */
    /* align-items: flex-end; */
    /* justify-content: flex-start; */
    /* flex-direction: column; */
}


.resume-buttons a {
    background: #f4bedc;
    color: #351d77;
    border-radius: 15px;
    padding: 5px 10px;
    text-decoration: none;
    
    font-weight: bold;
}





/*----- TABLET -----*/
@media screen and (min-width: 768px) {
    /* tablet basics */
    .container {
        max-width: 640px;
    }

    /* tablet projects */
    #projects {
        width: 100%;
    }

    .project-items {
        width: 100%;
        flex-flow: row wrap;
        align-items: start;
        justify-content: space-around;
    }

    .project {
        width: 45%;
    }

    /* tablet skills */
    #skills ul {
        text-align: center;
    }

    #skills li {
        display: inline-block;
        margin: 10px;
        width: 40%;
    }

    /* tablet footer */
    .footer-content {
        width: 100%;
        display: flex;
        justify-content: space-around;
    }

    .message-me-container, .social-icons-container {
        width: 40%;
    }

    /* tablet resume */
    #resume {
        padding: 0 10%;
    }

    #resume .header-row.flex-row, #resume .skills-and-exp-row.flex-row {
        align-items: stretch;
    }

    #resume .name-block, #resume .contact-block, #resume .project-block, #resume .bio-block {
        width: 50%;
    }

    #resume .bio-row.flex-row {
        align-items: start;
    }

    #resume .blue-block {
        width: 30%;
    }


    #resume .exp-block {
        width: 70%;
    }

    #resume .blue-block.flex-col {
        justify-content: start;
    }

}

/*----- DESKTOP -----*/
@media screen and (min-width: 1200px) {
    .container {
        max-width: 960px;
    }

    /* desktop header */
    header nav ul {
        width: 45%;
    }

    /* desktop projects */
    .project {
        width: 30%;
    }

    /* desktop skills */
    #skills li {
        width: 25%;
    }

    /* desktop about */
    #about .content-wrapper {
        flex-flow: row wrap;
    }

    .about-me-blurb {
        width: 45%;
    }

    /* desktop resume */
    #resume {
        padding: 0 25%;
    }
}



/*----- PRINT -----*/
@page {
    margin: 0;
    width: 8.5in;
}

@media only print {

    html {
        font-size: 11px;
    }

    body {
        font-size: 1em;
        background: var(--light-bg-accent);
    }

    /* hide all the buttons */
    #button-slider, .buttons-button, #always-visible {
        display: none;
    }

    /* print resume */

        #resume .header-row.flex-row, #resume .skills-and-exp-row.flex-row {
            align-items: stretch;
        }

        #resume .name-block, #resume .contact-block, #resume .project-block, #resume .bio-block {
            width: 50%;
        }

        #resume .bio-row.flex-row {
            align-items: start;
        }

        #resume .blue-block {
            width: 30%;
        }

        #resume .exp-block {
            width: 70%;
        }

        #resume .blue-block.flex-col {
            justify-content: start;
        }

    .reveal {
        transform: translateY(0);
        opacity: 1;
    }

    #resume .reveal h3, #resume h3.reveal {
        text-shadow: none;
    }

    #resume .exp-block .reveal .job-col:after {
        height: 100%;
    }


}

